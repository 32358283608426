/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
import axios from 'axios'
import store from '../store'
import router from '../router/index'
import config from '../config/index'
import api from './apiList'
import QS from 'querystring';
import Vue from 'vue';
import debounce from 'lodash/debounce'
import { Toast } from 'vant';

// 请求路径       
//axios.defaults.baseURL = '/api';
axios.defaults.baseURL = config.baseUrl;
// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
//通知后台表时为Ajax请求
// axios.defaults.headers.post['x-requested-with'] = 'XMLHttpRequest'
// 请求超时时间
axios.defaults.timeout = 30000
// 允许携带cookie
axios.defaults.withCredentials = true;

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
    store.commit('clearUserInfo');
    router.replace({
      name: 'auth'
    });
}
// 多个接口同时请求单位时间内都返回登录失效进行节流处理（避免连续弹出未登录弹框）
let loginExpireNotice = debounce((cb) => {
    Toast('请先登录');
    cb && cb();
}, 1000, {
    leading: true, // 延迟开始前调用
    trailing: false // 延迟结束后不调用
});

// 响应拦截器
axios.interceptors.response.use(
  response => {
    if (response.status === 200) {
      return Promise.resolve(response);
      // if (response.data.responeCode === '0') {
      //   return Promise.resolve(response)
      // } else {
      //   Toast(response.data.responeMsg);
      // }
    } else {
      return Promise.reject(response)
    }
  },
  // 服务器状态码不是200的情况
  error => {
    if (error.response.status) {
      switch (error.response.status) {
        case 310:
          loginExpireNotice(toLogin);//未登录
          break;
        case 311:
          Toast('无权限访问');
          break;
        case 404:
          Toast('请求路径错误');
          break;
        case 500:
          Toast('服务器请求失败');
          break;
        // 其他错误
        default:
          Toast(error.response.status + ' ' + error.response.statusText);
      }
    } else {
      Toast('请求失败');
    }
  }
)

//通过axios发送请求
export default {
    /**
    * get 请求
    * @param{String} urlKey 对应API 中的urlkey
    * @param{Object} paramObj  发送ajax 传递的参数对象
    * @param{Boolean} showLoading  是否显示加载中的图标
    * @returns {promise} 返回promise对象
    */
    get(urlKey, paramObj, showLoading = true) {
        let myconfig = {
          params: paramObj
        }
        if (showLoading) {
          store.commit('changePromisings', 'add');
        }
        return axios.get(api[urlKey], myconfig).then(res => {
          if (res && res.data) {
            return res.data
          } else {
            return {}
          }
        }).catch(err => {
          console.warn(`接口名: ${api[urlKey]}, 错误信息: `, err);
        }).finally(() => {
          if (showLoading) {
            store.commit('changePromisings', 'del');
          }
        });
    },
    /**
     * post 请求
     * @param{String} urlKey 对应API 中的urlkey
     * @param{Object} paramObj  发送ajax 传递的参数对象
     * @param{Object} headerConfig  请求头信息
     * @param{Boolean} showLoading  是否显示加载中的图标
     * @returns {promise} 返回promise对象
     */
    post(urlKey, paramObj, showLoading = true) {
        let param = QS.stringify(paramObj)
        if (showLoading) {
          store.commit('changePromisings', 'add');
        }
        return axios.post(api[urlKey], param).then(res => {
          if (res && res.data) {
            return res.data
          } else {
            return {}
          }
        }).catch(err => {
          console.warn(`接口名: ${api[urlKey]}, 错误信息: `, err);
        }).finally(() => {
          if (showLoading) {
            store.commit('changePromisings', 'del');
          }
        });
    },
    postString(urlKey, paramObj, showLoading = true) {    
        if (showLoading) {
          store.commit('changePromisings', 'add');
        }
        return axios.post(
          api[urlKey],
          JSON.stringify(paramObj), {
          headers: {
            'Content-Type': 'application/json; charset=UTF-8'
          }
        }).then(res => {
          if (res && res.data) {
            return res.data
          } else {
            return {}
          }
        }).catch(err => {
          console.warn(`接口名: ${api[urlKey]}, 错误信息: `, err);
        }).finally(() => {
          if (showLoading) {
            store.commit('changePromisings', 'del');
          }
        });
    },
};
