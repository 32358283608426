import Vue from 'vue'
import Vuex from 'vuex'
import ajax from '@/api/index.js';
import { Toast } from 'vant';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      //页面是否显示加载中
      isLoading: false,
      //当前正在加载中的接口个数
      promisings : 0,
      //当前用户信息
      userInfo : JSON.parse(localStorage.getItem('userInfo')) || {},
      //需要缓存的页面
      keepAlivePage : ['mine','storeDeviceList','projectPerformance','storePerformance','staffPerformance','shop','selectItem'],
  },
  getters: {
    //是否显示页面加载中
    isLoading : state => {
      return state.isLoading;
    },
    //需要缓存的页面
    keepAlivePage : state => {
      return state.keepAlivePage;
    },
  },
  mutations: {
    /**
     * 更改请求中接口的个数
     * @param {*} state 
     * @param {*} type 
     */
    changePromisings (state,type) {
        if (type === 'add') {
            state.promisings++;
        } else if (type === 'del') {
            state.promisings--;
        }
        if (state.promisings > 0) {
            state.isLoading = true;
        } else {
            state.isLoading = false;
        }
    },
    /**
     * 更新用户信息
     * @param {*} state 
     * @param {*} arg 
     */
    updateUserInfo(state, arg) {
        state.userInfo = arg;
        localStorage.setItem('userInfo',JSON.stringify(state.userInfo));
    },
    /**
     * 清除本地缓存
     * @param {*} state 
     */
    clearUserInfo(state) {
        state.userInfo = null;
        localStorage.removeItem('userInfo');
        localStorage.removeItem('userOpenId');
    },
    /**
     * 将组件添加到缓存
     */
    addKeepAlive(state,name){
      state.keepAlivePage = state.keepAlivePage.concat(name);
    },
    /**
     * 将组件移出缓存
     */
     removeKeepAlive(state,name){
       const keepAlivePage = state.keepAlivePage;
       const index = keepAlivePage.indexOf(name);
       if(index>-1){
        keepAlivePage.splice(index,1)
       }
    },
  },
  actions : {
    /**
     * 获取用户信息
     */
    getUserInfo( {commit}){
        return new Promise((resolve, reject) => {
            ajax.post('getUserInfo').then(res => {
                if(res.responeCode==='0'  && res.data && res.data.openid){
                    localStorage.setItem("userOpenId", res.data.openid);
                    commit('updateUserInfo',res.data || {});
                    resolve(res.data);
                } else {
                    Toast(res.responeMsg || '用户信息获取失败');
                }
            }).catch(error => {
                reject(error);
            })
        });
    },
  },
})
