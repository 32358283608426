import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//阻止vue在启动时生成生产提示
Vue.config.productionTip = false

//引入vant插件
import vant from './plugins/vant'
Vue.use(vant);

//引入日期插件
import moment from "moment";
Vue.prototype.$moment = moment;

//内容过滤器，如果内容为空或null，返回'-'
Vue.filter('contentFilter', function (content) {
    if (content === '' || content === null || typeof content === "undefined") {
        return '-';
    } else {
        return content;
    }
})
//货比格式化
//* @param places 保留小数位数
//* @param symbol 货币前的符号（比如$,￥）
// * @param thousand 每3位数间隔的标志
//* @param decimal 小数点位置的符号，默认为"."可更改
Vue.filter('moneyFilter', function (content,places, symbol, thousand, decimal) {
    if (content === '' || content === null || typeof content === "undefined") {
        return '-';
    } else {
        // return (content === 0 || content === '0' || content === '0.00') ? '0.00' : Number(content).toCurrency();
        return '￥' + ((content === 0 || content === '0' || content === '0.00') ? '0.00' : Number(content).toFixed(2));
    }
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')



