<template>
    <div id="app">
        <!-- 接口请求时的加载动画 -->
        <Loding v-if="isLoading"/>
        <!-- 主体内容 -->
        <div class="main-con">
            <keep-alive :include="keepAlivePage">
                <router-view></router-view>
            </keep-alive>
        </div>
        <!-- 底部导航 -->
<!--        <footer-nav></footer-nav>-->
    </div>
</template>
<script>
import Loding from '@/components/loding';
import footerNav from '@/components/footerNav/index';
import { mapGetters } from 'vuex';
export default {
    name: 'app',
    components: {
        Loding,
        footerNav
    },
    computed: {
        ...mapGetters([
          'isLoading',
          'keepAlivePage',
        ])
    },
    created(){
        // this.$store.dispatch('getUserInfo')
        window.localStorage.setItem('scanUrl',location.href.split('#')[0]);
    },
}
</script>
<style lang="scss">
  @import url("./assets/css/base.css");
  body{
    font-size: 24px;
    color :#666;
    font-family: "微软雅黑", "Microsoft YaHei" !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
  }  
  .main-con{
    // background-color: #F7F9FC;
    // padding-left:24px;
    // padding-right: 24px;
    // padding-top: 24px;
    // box-sizing: border-box;
    // height: 100vh;
    // overflow: auto;
  }
  .scroll{
    width: 100%;
    height: 100vh;
    padding-left:24px;
    padding-right: 24px;
    padding-top: 24px;
    padding-bottom: 24px;
    box-sizing: border-box;
    background-color: #F7F9FC;    
    overflow: scroll;
  }
  .top-title {
      color: #1d1d1d;
      font-size: 28px;
      margin-bottom: 20px;
  }
  .scrollH {
    height: calc(100vh - 100px);
  }
  .van-toast{
    font-size: 24px !important;
  }
  /deep/ .van-button::before{
      background-color:transparent;
  }
</style>
