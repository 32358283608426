import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routerList';
import config from '../config/index';

Vue.use(VueRouter)

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  mode: "history"
});
router.beforeEach((to, from, next) => {
    // window.scrollTo(0, 0);
    let _userOpenId = localStorage.getItem("userOpenId");
    
    //设置标题
    if(to.meta && to.meta.title){
        document.title = to.meta.title;
    } else {
        document.title = '氢圈';
    }
    if(_userOpenId){
        next();
    } else {
        if (to.name !== "auth") {
            // 判断当前是否为授权页面
            let redirect_uri = `${window.location.origin}/auth`;//回调地址
            let state = to.name;//自定义控制逻辑 微信授权后跳回的地址
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${config.appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`;
            //window.location.href = redirect_uri;
        } else {
            next();
        }
    }
});

export default router
