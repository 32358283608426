<!-- 
  底部导航
 -->
<template>
    <div id="nav" v-if="isShow">
        <router-link :to="{name : 'shop'}">
            <div class="icon icon-shop"></div>
            <span>门店</span>
        </router-link>
        <router-link :to="{ name: 'mine'}">
            <div class="icon icon-mine"></div>
            <span>我的</span>
        </router-link>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
      return {
        //是否显示导航
        isShow: false
      };
  },
  computed : {
    ...mapGetters(['module'])
  },
  watch : {
    $route(to,from) {
      this.isShow = /shop|mine/.test(to.name);
    }
  },
  mounted () {
    this.isShow = /shop|mine/.test(this.$route.name);
  }
}
</script>
<style lang='scss' scoped>
#nav {
    width: 100%;
    height: 98px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #999999;
    font-size: 20px;
    box-shadow: 0 -1px #ccc;
    z-index: 100;
    a {
        padding: 14px 0;
        box-sizing: border-box;
        width: 33.3%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        .icon{
            width: 45px;
            height: 45px;
        }
        .icon-shop{
            background: url("~@/assets/img/icon/shop.png") no-repeat;
            background-size: 100% 100%;
        }
        .icon-mine{
            background: url("~@/assets/img/icon/mine.png") no-repeat;
            background-size: 100% 100%;
        }
        &.router-link-exact-active {
            color: #FF0202;
            .icon-shop {
                background: url("~@/assets/img/icon/shop_selected.png") no-repeat;
                background-size: 100% 100%;
            }
            .icon-mine {
                background: url("~@/assets/img/icon/mine_selected.png") no-repeat;
                background-size: 100% 100%;
            }
        }
    }
}
</style>