export default [
    //微信授权
    {
        path: "/auth",
        name: "auth",
        component: () => import(/* webpackChunkName: "Auth" */ '@/views/auth/index.vue'),
    },
    //门店
    {
        path: '/shop',
        name: 'shop',
        component: () => import(/* webpackChunkName: "shop" */ '@/views/shop/index.vue'),
        meta : {
            title : '氢圈',
        }
    },
    //门店详情
    {
        path: '/shopDetail',
        name: 'detail',
        component: () => import(/* webpackChunkName: "shop" */ '@/views/shop/detail.vue'),
        meta : {
            title : '门店详情',
        }
    },    
    //我的
    {
        path: '/mine',
        name: 'mine',
        component: () => import(/* webpackChunkName: "mine" */ '@/views/mine/index.vue'),
        meta : {
            title : '氢圈',
        }
    },    
    //会员注册
    {
        path: "/register/member",
        name: "memberRegister",
        component: () => import(/* webpackChunkName: "Auth" */ '@/views/register/memberRegister.vue'),
        meta : {
            title : '会员注册',
        }
    }, 
    //员工注册
    {
        path: "/register/staff",
        name: "staffRegister",
        component: () => import(/* webpackChunkName: "Auth" */ '@/views/register/staffRegister.vue'),
        meta : {
            title : '员工注册',
        }
    },
    //管理人注册
    {
        path: "/register/keeper",
        name: "keeperRegister",
        component: () => import(/* webpackChunkName: "Auth" */ '@/views/register/keeperRegister.vue'),
        meta : {
            title : '管理人注册',
        }
    },  
    //我的会员卡
    {
        path: "/mine/member/card",
        name: "memberCard",
        component: () => import(/* webpackChunkName: "Auth" */ '@/views/mine/member/myClubCard.vue'),
        meta : {
            title : '我的会员卡',
        }
    },
    //我的套餐
    {
        path: "/mine/member/package",
        name: "memberPackage",
        component: () => import(/* webpackChunkName: "Auth" */ '@/views/mine/member/myPackage.vue'),
        meta : {
            title : '我的套餐',
        }
    },
    //选择项目
    {
        path: "/mine/member/selectItem",
        name: "selectItem",
        component: () => import(/* webpackChunkName: "Auth" */ '@/views/mine/member/selectItem.vue'),
        meta : {
            title : '选择项目',
        }
    },
    //选择员工
    {
        path: "/mine/member/selectStaff",
        name: "selectStaff",
        component: () => import(/* webpackChunkName: "Auth" */ '@/views/mine/member/selectStaff.vue'),
        meta : {
            title : '选择员工',
        }
    },
    //选择服务时间
    {
        path: "/mine/member/selectTime",
        name: "selectTime",
        component: () => import(/* webpackChunkName: "Auth" */ '@/views/mine/member/selectTime.vue'),
        meta : {
            title : '选择服务时间',
        }
    },       
    //服务记录
    {
        path: "/mine/member/serviceRecord",
        name: "serviceRecord",
        component: () => import(/* webpackChunkName: "Auth" */ '@/views/mine/member/serviceRecord.vue'),
        meta : {
            title : '服务记录',
        }
    },
    //我的积分
    {
        path: "/mine/member/myPoints",
        name: "myPoints",
        component: () => import(/* webpackChunkName: "Auth" */ '@/views/mine/member/myPoints.vue'),
        meta : {
            title : '我的积分',
        }
    },
    //预约记录
    {
        path: "/mine/member/bookRecord",
        name: "bookRecord",
        component: () => import(/* webpackChunkName: "Auth" */ '@/views/mine/member/bookRecord.vue'),
        meta : {
            title : '预约记录',
        }
    },    
    //我的考勤
    {
        path: "/myAttendance",
        name: "myAttendance",
        component: () => import(/* webpackChunkName: "Auth" */ '@/views/mine/staff/myAttendance.vue'),
        meta : {
            title : '我的考勤',
        }
    },
    //我的提成
    {
        path: "/myCommission",
        name: "myCommission",
        component: () => import(/* webpackChunkName: "Auth" */ '@/views/mine/staff/myCommission.vue'),
        meta : {
            title : '我的提成',
        }
    },
    //我的业绩
    {
        path: "/myPerformance",
        name: "myPerformance",
        component: () => import(/* webpackChunkName: "Auth" */ '@/views/mine/staff/myPerformance.vue'),
        meta : {
            title : '我的业绩',
        }
    },
    //我的总览
    {
        path: "/myOverview",
        name: "myOverview",
        component: () => import(/* webpackChunkName: "Auth" */ '@/views/mine/staff/myOverview.vue'),
        meta : {
            title : '我的总览',
        }
    },
    //店内业绩
    {
        path: '/storePerformance',
        name: 'storePerformance',
        component: () => import(/* webpackChunkName: "mine" */ '@/views/mine/keeper/storePerformance.vue'),
        meta : {
            title : '店内业绩',
        }
    },
    //项目业绩
    {
        path: '/projectPerformance',
        name: 'projectPerformance',
        component: () => import(/* webpackChunkName: "mine" */ '@/views/mine/keeper/projectPerformance.vue'),
        meta : {
            title : '项目业绩',
        }
    },
    //员工业绩
    {
        path: '/staffPerformance',
        name: 'staffPerformance',
        component: () => import(/* webpackChunkName: "mine" */ '@/views/mine/keeper/staffPerformance.vue'),
        meta : {
            title : '员工业绩',
        }
    },
    //门店仪器
    {
        path: '/storeDeviceList',
        name: 'storeDeviceList',
        component: () => import(/* webpackChunkName: "mine" */ '@/views/mine/keeper/storeDeviceList.vue'),
        meta : {
            title : '门店仪器',
        }
    },
    //解锁仪器
    {
        path: '/storeDeviceDetail',
        name: 'storeDeviceDetail',
        component: () => import(/* webpackChunkName: "mine" */ '@/views/mine/keeper/storeDeviceDetail.vue'),
        meta : {
            title : '解锁仪器',
        }
    },
    //我的考勤
    {
        path: '/storeDeviceDetail',
        name: 'storeDeviceDetail',
        component: () => import(/* webpackChunkName: "mine" */ '@/views/mine/keeper/storeDeviceDetail.vue'),
        meta : {
            title : '我的考勤',
        }
    },
    //通配符的路由放最后
    {
        path: "/*",
        redirect: { name: 'mine' },
    }
]