import {
  Button,
  Loading,
  Popup,
  Toast,
  DatetimePicker,
  Icon,
  ActionSheet,
  Cell,
  CellGroup,
  Field,
  Overlay,
  Sticky,
  Divider,
  Empty,
  Search ,
  List ,
  Dialog,
} from 'vant'
let vantUIArr = [Button, Loading, Popup, Toast ,DatetimePicker, Icon , ActionSheet, Cell, CellGroup, Field , Overlay, Sticky,Divider , Empty,Search,List,Dialog  ];
export default {
  install (Vue) {
    vantUIArr.forEach(vantUI => {
      Vue.component(vantUI.name,vantUI)
    })
  }
}