export default {
    /********************公用******************** */
    //获取验证码
    'getRegisterSmsCode' : '/wechat/user/getRegisterSmsCode.do',
    //授权登录
    'auth2Login' : '/public/wechat/auth2Login.do',
    //获取签名参数
    'getJsConfig' : '/public/wechat/getJsConfig.do',
    //获取门店列表
    'qryStoreList' : '/public/store/qryStorePage.do',
    //某门店的产品列表
    'qryProjectPage' : '/public/store/qryProjectPage.do',
    //门店点赞
    'storeThumbs' : '/wechat/user/thumbs.do',
    //获取仪器列表
    'getMachineInfos' : '/wechat/user/getMachineInfos.do',
    //绑定仪器
    'bindMachine' : '/wechat/user/bindMachine.do',
    //解锁仪器
    'unlockMachine' : '/wechat/user/unlockMachine.do',
    //刷新仪器状态
    'refreshMachineState' : '/wechat/user/refreshMachineState.do',
    //根据仪器编号查询设备明细
    'getMachineInfo' : '/wechat/user/getMachineInfo.do',
    //移除仪器
    'delMachine' : '/wechat/user/delMachine.do',
    

    /********************会员******************** */
    //会员注册
    'customerRegister' : '/wechat/user/customerRegister.do',
    //获取用户信息
    'getUserInfo':'/wechat/user/getUserInfo.do',    
    //获取所有门店
    'getCustomerStores' : '/wechat/user/getCustomerStores.do',
    //切换当前用户的门店id
    'changeUserStoreId' : '/wechat/user/changeStoreId.do',
    //获取当前会员的资产
    'getCustomerAsset' : '/wechat/user/getCustomerAsset.do',
    //获取门店会员的卡列表
    'getCustomerCards' : '/wechat/user/getCustomerCards.do',
    //获取门店会员的套餐列表
    'getCustomerProjectItems' : '/wechat/user/getCustomerProjectItems.do',
    //获取门店会员的消费记录列表
    'getCustomerOrders' : '/wechat/user/getCustomerOrders.do',
    //获取当前选中门店预约员工列表
    'getCurrentStoreStaffs' : '/wechat/user/getCurrentStoreStaffs.do',
    //获取门店预约时间列表
    'getStoreBookTimes' : '/wechat/user/getStoreBookTimes.do',
    //提交预约保存
    'doBooking' : '/wechat/user/doBooking.do',
    // 获取会员用户的预约信息-有且只会返回最近一条记录
    'getMyBooking' : '/wechat/user/getBooking.do',
    // 我的预约记录列表
    'getCustomerBookings' : '/wechat/user/getCustomerBookings.do',
    // 客户取消预约
    'doCancelBooking' : '/wechat/user/doCancelBooking.do',
    //获取项目列表
    'getStoreBookProjects' : '/wechat/user/getStoreBookProjects.do',    
    //我的积分
    'getCustomerScore'  :'/wechat/user/getCustomerScore.do',


    /********************员工******************** */
    //员工注册
    'staffRegister' : '/wechat/user/staffRegister.do',
    //员工业绩汇总
    'staffSummary'  : '/wechat/user/staffSummary.do',
    //我的业绩、提成
    'staffSummaryResults' : '/wechat/user/staffSummaryResults.do',
    // 员工打卡
    'userDoWork' : '/wechat/user/doWork.do',
    // 员工的打卡记录列表|不分页
    'getStaffWorks' : '/wechat/user/getStaffWorks.do',

    /********************老板******************** */
    // 老板注册
    'bossRegister' : '/wechat/user/bossRegister.do',
    // 店内业绩
    'storeSummary' : '/wechat/user/storeSummary.do',
    // 门店项目业绩
    'storeProjectCnt' : '/wechat/user/storeProjectCnt.do',
    // 门店员工业绩
    'storeStaffResults' : '/wechat/user/storeStaffResults.do',

}